var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "el-popover",
        {
          ref: _vm.data[_vm.popovers.parentField],
          staticClass: "popover_wrap",
          attrs: {
            placement: _vm.popovers.placement,
            width: _vm.popovers.width,
            trigger: _vm.popovers.trigger
          }
        },
        [
          _vm.popovers.popType == "table" ||
          _vm.popovers.popType == "table_tail"
            ? [
                _vm.popovers.status == "updateta"
                  ? _c(
                      "div",
                      { staticClass: "display_ib mr10 mb10" },
                      [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("物料编号：")
                        ]),
                        _c("el-input", {
                          staticClass: "input_single w200 mr10",
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.data[_vm.popovers.parentField],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data,
                                _vm.popovers.parentField,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "data[popovers.parentField]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-table",
                  {
                    staticClass: "popover_table",
                    attrs: { data: _vm.popoverList }
                  },
                  _vm._l(_vm.popovers.fields, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        width: item.width,
                        prop: item.field,
                        label: item.name
                      }
                    })
                  }),
                  1
                ),
                _vm.popovers.popType == "table_tail"
                  ? _c("div", { staticClass: "mt10" }, [
                      _vm._v(_vm._s(_vm.data[_vm.popovers.tail]))
                    ])
                  : _vm._e(),
                _vm.popovers.status == "updateta"
                  ? _c(
                      "div",
                      { staticClass: "mt10" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.data[_vm.popovers.parentField]
                            },
                            on: {
                              click: function($event) {
                                return _vm.cancelpopoverHandle(
                                  _vm.curIndex,
                                  _vm.data,
                                  _vm.popovers.parentName,
                                  _vm.data[_vm.popovers.parentField]
                                )
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.data[_vm.popovers.parentField]
                            },
                            on: {
                              click: function($event) {
                                return _vm.savepopoverHandle(
                                  _vm.curIndex,
                                  _vm.data,
                                  _vm.popovers.parentName,
                                  _vm.data[_vm.popovers.parentField]
                                )
                              }
                            }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      title: _vm.data[_vm.popovers.parentField]
                    },
                    on: {
                      click: function($event) {
                        return _vm.popoverHandle(
                          _vm.curIndex,
                          _vm.data,
                          _vm.popovers.parentName,
                          _vm.popovers.parentField
                        )
                      }
                    },
                    slot: "reference"
                  },
                  [_vm._v(_vm._s(_vm.data[_vm.popovers.parentField]))]
                )
              ]
            : _vm._e(),
          _vm.popovers.popType == "table_json"
            ? [
                _c(
                  "el-table",
                  {
                    staticClass: "popover_table",
                    attrs: { data: _vm.popoverList }
                  },
                  _vm._l(_vm.popovers.fields, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        width: item.width,
                        prop: item.field,
                        label: item.name
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("json-viewer", {
                                  attrs: { value: scope.row.saleOrder }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      title: _vm.data[_vm.popovers.parentField]
                    },
                    on: {
                      click: function($event) {
                        return _vm.popoverHandle(
                          _vm.curIndex,
                          _vm.data,
                          _vm.popovers.parentName,
                          _vm.popovers.parentField
                        )
                      }
                    },
                    slot: "reference"
                  },
                  [_vm._v(_vm._s(_vm.data[_vm.popovers.parentField]))]
                )
              ]
            : _vm._e(),
          _vm.popovers.popType == "table_double"
            ? [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.popoverList }
                  },
                  _vm._l(_vm.popovers.fields, function(item, index) {
                    return _c(
                      "el-table-column",
                      { key: index, attrs: { label: item.name } },
                      _vm._l(item.children, function(cItem, cIndex) {
                        return _c("el-table-column", {
                          key: cIndex,
                          attrs: {
                            width: cItem.width,
                            prop: cItem.field,
                            label: cItem.name
                          }
                        })
                      }),
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      title: _vm.data[_vm.popovers.parentField]
                    },
                    on: {
                      click: function($event) {
                        return _vm.popoverHandle(
                          _vm.curIndex,
                          _vm.data,
                          _vm.popovers.parentName,
                          _vm.popovers.parentField
                        )
                      }
                    },
                    slot: "reference"
                  },
                  [_vm._v(_vm._s(_vm.data[_vm.popovers.parentField]))]
                )
              ]
            : _vm._e(),
          _vm.popovers.popType == "table_tab_tail"
            ? [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.popovers.tabs, function(item, index) {
                    return _c(
                      "el-tab-pane",
                      {
                        key: index,
                        attrs: { label: item.name, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "popover_table",
                            attrs: { data: _vm.popoverTabData[item.listName] }
                          },
                          _vm._l(item.fields, function(fItem, fIndex) {
                            return _c("el-table-column", {
                              key: fIndex,
                              attrs: {
                                width: fItem.width,
                                prop: fItem.field,
                                label: fItem.name
                              }
                            })
                          }),
                          1
                        ),
                        _vm.popovers.popType == "table_tab_tail"
                          ? _c("div", { staticClass: "mt10" }, [
                              item.total
                                ? _c("span", [
                                    _vm._v(
                                      "总计：" +
                                        _vm._s(
                                          _vm.popoverTabData[item.total] ==
                                            undefined
                                            ? 0
                                            : _vm.popoverTabData[item.total]
                                        )
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.data[_vm.popovers.tail]))
                                  ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      title: _vm.data[_vm.popovers.parentField]
                    },
                    on: {
                      click: function($event) {
                        return _vm.popoverHandle(
                          _vm.curIndex,
                          _vm.data,
                          _vm.popovers.parentName,
                          _vm.popovers.parentField
                        )
                      }
                    },
                    slot: "reference"
                  },
                  [_vm._v(_vm._s(_vm.data[_vm.popovers.parentField]))]
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }