var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "table",
      staticClass: "singleTableList",
      staticStyle: { width: "100%" },
      style: _vm.loading ? "min-height: 598px;" : "",
      attrs: { data: _vm.list, border: "", stripe: "", "max-height": "700px" },
      on: {
        "row-dblclick": _vm.dbSelected,
        "selection-change": _vm.handleSelectionChange
      }
    },
    [
      _vm.ifCheckbox
        ? _c("el-table-column", { attrs: { type: "selection", width: "55" } })
        : _vm._e(),
      _vm._l(_vm.leaderFieldsList, function(item, index) {
        return _c("el-table-column", {
          key: index,
          attrs: {
            fixed: "",
            prop: item.field,
            label: item.name,
            title: item.field,
            width: item.width ? item.width : "",
            "min-width": item.minWidth ? item.minWidth : ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(scope) {
                  return [
                    item.hasPopover
                      ? _c(
                          "div",
                          [
                            item.hasPopover
                              ? _c("Popover", {
                                  attrs: {
                                    popSet: item.popovers,
                                    popData: scope.row,
                                    curIndex: scope.$index
                                  },
                                  on: {
                                    popoverHandle: _vm.popoverHandle,
                                    savepopoverHandle: _vm.savepopoverHandle,
                                    cancelpopoverHandle: _vm.cancelpopoverHandle
                                  }
                                })
                              : _c("span")
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { attrs: { title: scope.row[item.field] } },
                          [_vm._v(_vm._s(scope.row[item.field]))]
                        )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      }),
      _vm._l(_vm.fieldsList, function(item, index) {
        return _c("el-table-column", {
          key: index + 1,
          attrs: {
            prop: item.field,
            label: item.name,
            title: item.field,
            width: item.width ? item.width : "",
            "min-width": item.minWidth ? item.minWidth : ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(scope) {
                  return [
                    item.isOffered
                      ? _c("span", [
                          scope.row.purchaseMeasurementUnit == "米"
                            ? _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      (scope.row[item.field_1]
                                        ? scope.row[item.field_1]
                                        : "0") +
                                      "包 " +
                                      (scope.row[item.field_2]
                                        ? scope.row[item.field_2]
                                        : "0") +
                                      "米/包 " +
                                      (scope.row[item.field_3]
                                        ? scope.row[item.field_3]
                                        : "0") +
                                      "米"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (scope.row[item.field_1]
                                        ? scope.row[item.field_1]
                                        : "0") + "包 "
                                    ) +
                                      _vm._s(
                                        (scope.row[item.field_2]
                                          ? scope.row[item.field_2]
                                          : "0") + "米/包 "
                                      ) +
                                      _vm._s(
                                        (scope.row[item.field_3]
                                          ? scope.row[item.field_3]
                                          : "0") + "米"
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      scope.row[item.field_1] +
                                      scope.row.purchaseMeasurementUnit
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row[item.field_1] +
                                        scope.row.purchaseMeasurementUnit
                                    )
                                  )
                                ]
                              )
                        ])
                      : _vm._e(),
                    item.isOffereds
                      ? _c("span", [
                          scope.row.purchaseMeasurementUnit == "米"
                            ? _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      (scope.row[item.field_1]
                                        ? scope.row[item.field_1]
                                        : "0") +
                                      "包 " +
                                      (scope.row[item.field_2]
                                        ? scope.row[item.field_2]
                                        : "0") +
                                      "米/包 " +
                                      (scope.row[item.field_3]
                                        ? scope.row[item.field_3]
                                        : "0") +
                                      "米"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (scope.row[item.field_1]
                                        ? scope.row[item.field_1]
                                        : "0") + "包 "
                                    ) +
                                      _vm._s(
                                        (scope.row[item.field_2]
                                          ? scope.row[item.field_2]
                                          : "0") + "米/包 "
                                      ) +
                                      _vm._s(
                                        (scope.row[item.field_3]
                                          ? scope.row[item.field_3]
                                          : "0") + "米"
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      scope.row[item.field_3] +
                                      scope.row.purchaseMeasurementUnit
                                  }
                                },
                                [
                                  scope.row.purchaseMeasurementUnit != null &&
                                  scope.row.purchaseMeasurementUnit != ""
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (scope.row[item.field_3]
                                              ? scope.row[item.field_3]
                                              : "0") +
                                              scope.row.purchaseMeasurementUnit
                                          )
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (scope.row[item.field_3]
                                              ? scope.row[item.field_3]
                                              : "0") + "条"
                                          )
                                        )
                                      ])
                                ]
                              )
                        ])
                      : _vm._e(),
                    item.isId
                      ? _c("div", [
                          _c("span", [_vm._v(_vm._s(scope.$index + 1))])
                        ])
                      : _vm._e(),
                    item.proHandle
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.productTypeHandle(
                                  scope.row.productClassification
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.sellSeasonHandle
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.productSellSeasonHandle(
                                  scope.row.sellSeason
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricMaterial
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fabricMaterialTypeHandle(
                                  scope.row.materialType
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricHolidayType
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fabricHolidayTypeHandle(
                                  scope.row.holidayType
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricfactorySeason
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fabricfactorySeasonTypeHandle(
                                  scope.row.factorySeason
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricSupplier
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fabricSuppliertypeHandle(
                                  scope.row.supplierId,
                                  "supplierId"
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricSupplierName
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fabricSuppliertypeHandle(
                                  scope.row.supplierName,
                                  "supplierName"
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricSupplierType
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.supplierTypeHandle(
                                  scope.row.customerCategory
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.spotPriceCurreny
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.spotPriceCurrenyHandle(scope.row.currency)
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabricSupplierStatus
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.supplierStatusHandle(
                                  scope.row.customerStatus
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.proStatus
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.productStatusHandle(scope.row.productStatus)
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.productClassification
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.classificationHandle(
                                  scope.row.productClassification
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.materStatus
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.productStatusHandle(
                                  scope.row.materialStatus
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.materialPop
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.materialTypeHandle(
                                  scope.row.materialProperties
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.materialHandle
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.typeHandle(
                                  scope.row.materialClassification,
                                  _vm.MaterialClasstypelist
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.materialClass
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.typeHandle(
                                  scope.row.materialClassification,
                                  _vm.materialClassList
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.fabrictype
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.typeHandle(
                                  scope.row.materialType,
                                  _vm.fabrictypeList
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.isSale
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.sale == true ? "可售" : "停售")
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.isDevelop
                      ? _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(!scope.row.developCode ? "否" : "是"))
                          ])
                        ])
                      : _vm._e(),
                    item.isMobileGoods
                      ? _c("div", [
                          _vm._v(_vm._s(!scope.row.MobileGoods ? "否" : "是"))
                        ])
                      : _vm._e(),
                    item.isQuickGoods
                      ? _c("div", [
                          _vm._v(_vm._s(!scope.row.isQuickGoods ? "否" : "是"))
                        ])
                      : _vm._e(),
                    item.isCheckbox
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              float: "left",
                              "padding-left": "8px"
                            }
                          },
                          [
                            _c("el-checkbox", {
                              key: scope.row.materialCode,
                              attrs: { label: scope.row.materialCode },
                              on: {
                                change: function($event) {
                                  return _vm.checkAllChange(scope.row)
                                }
                              },
                              model: {
                                value: _vm.checkAllList,
                                callback: function($$v) {
                                  _vm.checkAllList = $$v
                                },
                                expression: "checkAllList"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    item.hasPopover
                      ? _c(
                          "div",
                          [
                            item.hasPopover
                              ? _c("Popover", {
                                  attrs: {
                                    popSet: item.popovers,
                                    popData: scope.row,
                                    curIndex: scope.$index
                                  },
                                  on: {
                                    popoverHandle: _vm.popoverHandle,
                                    savepopoverHandle: _vm.savepopoverHandle,
                                    cancelpopoverHandle: _vm.cancelpopoverHandle
                                  }
                                })
                              : _c("span")
                          ],
                          1
                        )
                      : _vm._e(),
                    item.isSalesPlan
                      ? _c(
                          "span",
                          {
                            attrs: {
                              title:
                                scope.row["list"][item.index].predictQuantity
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row["list"][item.index].predictQuantity
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    item.isSingleOffered
                      ? _c("span", [
                          scope.row.purchaseMeasurementUnit == "米"
                            ? _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      (scope.row[item.field]
                                        ? scope.row[item.field]
                                        : "0") + "米"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (scope.row[item.field]
                                        ? scope.row[item.field]
                                        : "0") + "米 "
                                    )
                                  )
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      scope.row[item.field] +
                                      scope.row.purchaseMeasurementUnit
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row[item.field] +
                                        scope.row.purchaseMeasurementUnit
                                    )
                                  )
                                ]
                              )
                        ])
                      : _c(
                          "span",
                          { attrs: { title: scope.row[item.field] } },
                          [_vm._v(_vm._s(scope.row[item.field]))]
                        )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      }),
      _vm._l(_vm.tailFieldsList, function(item, index) {
        return _c("el-table-column", {
          key: index,
          attrs: {
            fixed: "right",
            label: item.name,
            width: item.width ? item.width : "",
            "min-width": item.minWidth ? item.minWidth : ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(scope) {
                  return item.isAction
                    ? [
                        item.hasEdit
                          ? _c("el-button", {
                              staticClass: "el-icon-edit",
                              attrs: {
                                type: "primary",
                                circle: "",
                                size: "mini",
                                title: "编辑"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.editHandle(scope.$index, scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasSubmit
                          ? _c("el-button", {
                              staticClass: "el-icon-check",
                              attrs: {
                                type: "success",
                                circle: "",
                                size: "mini",
                                title: "提交审批"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.submitHandle(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasDelete
                          ? _c("el-button", {
                              staticClass: "el-icon-delete",
                              attrs: {
                                type: "danger",
                                circle: "",
                                size: "mini",
                                title: "删除"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteHandle(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasCheck
                          ? _c("el-button", {
                              staticClass: "el-icon-document",
                              attrs: {
                                type: "primary",
                                circle: "",
                                size: "mini",
                                title: "查看"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.checkHandle(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasRemove
                          ? _c("el-button", {
                              staticClass: "el-icon-close",
                              attrs: {
                                type: "danger",
                                circle: "",
                                size: "mini",
                                title: "整单解除"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.removeHandle(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasPay
                          ? _c("el-button", {
                              staticClass: "el-icon-thumb",
                              attrs: {
                                type: "primary",
                                circle: "",
                                size: "mini",
                                title: "确认调拨"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.payHandle(scope.$index, scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasCopy
                          ? _c("el-button", {
                              staticClass: "el-icon-copy-document",
                              attrs: {
                                type: "primary",
                                circle: "",
                                size: "mini",
                                title: "克隆"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.cloneHandle(scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasDictionary
                          ? _c("el-button", {
                              staticClass: "el-icon-collection",
                              attrs: {
                                type: "primary",
                                circle: "",
                                size: "mini",
                                title: "字典项管理"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.dictionaryHandle(scope.row)
                                }
                              }
                            })
                          : _vm._e(),
                        item.hasRelease
                          ? _c("el-button", {
                              staticClass: "el-icon-close",
                              attrs: {
                                type: "warning",
                                circle: "",
                                size: "mini",
                                title: "占货解除"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.releaseHandle(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        scope.row.actions
                          ? [
                              scope.row.actions.hasEdit
                                ? _c("el-button", {
                                    staticClass: "el-icon-edit",
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "编辑"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.editHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.actions.hasSubmit
                                ? _c("el-button", {
                                    staticClass: "el-icon-check",
                                    attrs: {
                                      type: "success",
                                      circle: "",
                                      size: "mini",
                                      title: "提交审批"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.submitHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.actions.hasDelete
                                ? _c("el-button", {
                                    staticClass: "el-icon-delete",
                                    attrs: {
                                      type: "danger",
                                      circle: "",
                                      size: "mini",
                                      title: "删除"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.actions.hasCheck
                                ? _c("el-button", {
                                    staticClass: "el-icon-document",
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "查看"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.checkHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.actions.hasPay
                                ? _c("el-button", {
                                    staticClass: "el-icon-thumb",
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "确认调拨"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.payHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.actions.hasResend
                                ? _c("el-button", {
                                    staticClass: "el-icon-position",
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "重新发送"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.resendHandle(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : undefined
                }
              }
            ],
            null,
            true
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }