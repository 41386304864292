import http from "../http";
import axios from "axios";


/**
 * 分页-初始列表展示列表
 */
export const supplierSearch = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SUPPLIER_URL+`/supplier/v1/supplierCompanys/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};


/**
 * 供应商查看展示列表
 */
export const getsupplierview = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierCompanys/${uuid}`
  });
};


/**
 * 供应商删除单条数据
 */
export const getsupplierdel = (uuid) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierCompanys/${uuid}`
  });
};



/**
 * 新增供应商form列表
 */
export const supplierapiadd = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierCompanys`,
    data
  });
};



/**
 * 新增联系人form列表
 */
export const linkmanapiadd = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/contacts`,
    data
  });
};


/**
 * 新增地址form列表
 */
export const addressapiadd = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierAddress`,
    data
  });
};


/**
 * 联系人table列表
 */
export const linkmanapishow = (customerUuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/contacts/list/${customerUuid}`
  });
};

/**
 * 联系人单条点击详情
 */
export const linkmanapidetail = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/contacts/${uuid}`
  });
};

/**
 * 地址单条点击详情
 */
export const addressapidetail = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierAddress/${uuid}`
  });
};


/**
 * 联系人单条点击删除
 */
export const linkmanapidel = (uuid) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/contacts/${uuid}`
  });
};

/**
 * 地址单条点击删除
 */
export const addressapidel = (uuid) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierAddress/${uuid}`
  });
};


/**
 * 供应商状态列表
 */
export const getsupplierstatus = () => {
  let res ={}
  res.data = []
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTUREINFRASTRUCTURE_URL+ `/v1/constants/dicts?dictIndex= `
  // });
  return res 
};

/**
 * 供应商类型列表
 */
export const getsuppliertype = () => {
   let res ={}
   res.data = [
    {
      "id": 623,
      "rev": 1,
      "createTime": "2021-01-13T03:26:59.147+0000",
      "updateTime": "2021-01-13T03:26:59.147+0000",
      "dictIndex": "purchaseType",
      "dictItemValue": "B099999",
      "dictItemName": "世家宝-样本采购",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 147,
      "rev": 1,
      "createTime": "2020-03-18T03:26:37.515+0000",
      "updateTime": "2020-03-18T03:26:37.515+0000",
      "dictIndex": "purchaseType",
      "dictItemValue": "B010201",
      "dictItemName": "以销定采-职业装",
      "dictItemOrder": 2,
      "remark": "业务类型",
      "dictItemLanguage": "CH"
    },
    {
      "id": 612,
      "rev": 1,
      "createTime": "2020-10-26T04:00:39.133+0000",
      "updateTime": "2020-10-26T04:00:39.133+0000",
      "dictIndex": "purchaseType",
      "dictItemValue": "B010206",
      "dictItemName": "以销定采-职业装-面料生产",
      "dictItemOrder": 3,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 149,
      "rev": 1,
      "createTime": "2020-03-18T03:26:37.515+0000",
      "updateTime": "2020-03-18T03:26:37.515+0000",
      "dictIndex": "purchaseType",
      "dictItemValue": "B010401",
      "dictItemName": "以销定采-订货大样",
      "dictItemOrder": 4,
      "remark": "业务类型",
      "dictItemLanguage": "CH"
    },
    {
      "id": 140,
      "rev": 1,
      "createTime": "2020-10-19T03:42:17.000+0000",
      "updateTime": "2020-10-19T03:42:20.000+0000",
      "dictIndex": "purchaseType",
      "dictItemValue": "B010501",
      "dictItemName": "以销定采-订货大货",
      "dictItemOrder": 5,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 148,
      "rev": 1,
      "createTime": "2020-03-18T03:26:37.515+0000",
      "updateTime": "2020-03-18T03:26:37.515+0000",
      "dictIndex": "purchaseType",
    }]
  res.code='0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTUREINFRASTRUCTURE_URL+ `/v1/constants/dicts?dictIndex=SupplierCategory`
  // });
  return res
};

/**
 * 联系人列表
 */
export const getlinkmantype = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTUREINFRASTRUCTURE_URL+ `/v1/constants/dicts?dictIndex=ContactType`
  });
};

/**
 * 地址信息列表
 */
export const getaddressmessagetype = (customerUuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+ `/supplier/v1/supplierAddress/list/${customerUuid}`
  });
};



/**
 * 地址国家列表
 */
export const getaddressstatetype = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTUREINFRASTRUCTURE_URL+ `/v1/constants/dicts?dictIndex=Country`
  });
};


/**
 * 地址类型列表
 */
export const getaddresstype = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTUREINFRASTRUCTURE_URL+ `/v1/constants/dicts?dictIndex=AddressType`
  });
};


/**
 * 币种分类
 */
export const getaccountstatetype = () => {
  // 币种
  let res ={}
  res.data = [
    {"name": "人民币","index": "1"},
    {"name": "美元","index": "2"},
    {"name": "欧元1","index": "3"},
    {"name": "英镑","index": "4"},
    {"name": "日元","index": "5"},
    {"name": "韩元","index": "6"},
    {"name": "港币","index": "7"}
]
  res.code = '0'
    
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=Currency`
  // });
  return res 
};



/**
 * 新增采购计划管理信息获取uuid
 */
export const purchasegetuuid = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/getUUID`,
  });
};

